export default class Task {
  constructor(
    action,
    day,
    start,
    end,
    type,
    resume,
    description,
    patNumber,
    taskId,
    color,
    technician,
    created_by,
    created_at,
    updated_by,
    updated_at,
    officeColor,
    note,
    usersSelecteds,
    oldUser,
    isBooking,
    bookingId,
    localization,
    task_all_info,
    taskType,
  ) {
    this.action = action;
    this.day = day;
    this.start = start;
    this.end = end;
    this.type = type;
    this.resume = resume;
    this.description = description;
    this.patNumber = patNumber;
    this.taskId = taskId;
    this.color = color;
    this.usersSelecteds = usersSelecteds;
    this.technician = technician;
    this.created_by = created_by;
    this.created_at = created_at;
    this.updated_by = updated_by;
    this.updated_at = updated_at;
    this.officeColor = officeColor;
    this.note = note;
    this.oldUser = oldUser;
    this.isBooking = isBooking;
    this.bookingId = bookingId;
    this.localization = localization;
    this.task_all_info = task_all_info;
    this.taskType = taskType;

  }
}
